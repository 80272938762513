@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body * {
  font-size: 18px;
  font-family: "Barlow Semi Condensed";
  font-weight: 500;
  letter-spacing: .06125rem;
  font-variant: small-caps;
}

.App {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100vh;
  overflow-x: scroll;
  /* firefox scrollbar */
  scrollbar-width: none;
}

.App::-webkit-scrollbar {
  /* chrome, safari, opera */
  display: none;
}

.container {
  padding: 1rem;
}

@media (max-aspect-ratio: 1/1) {
  html, body {
    overflow: unset;
  }

  .App {
    height: unset;
    overflow-x: unset;
    flex-direction: column;
  }
}