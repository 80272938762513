.gallery {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.galleryImages{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
}

.galleryImage {
  height: 80vh;
  border-radius: .25rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

@media (max-aspect-ratio: 1/1) {
  .gallery {
    height: unset;
    width: 100%;
    flex-direction: column;
  }

  .galleryImages {
    flex-direction: column;
  }

  .galleryImage {
    height: unset;
    width: 100%;
  }
}