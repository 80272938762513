.slides {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  overflow: hidden;

  z-index: -1;
}

.slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}

.slide.visible {
  opacity: 1;
  z-index: 1;
}